import styled from 'styled-components';

export const LI = styled.li`
  /* display: inline-block; */
  color: #333;
  font-weight: normal;
  font-size: 18px;
  line-height: 32px;
  letter-spacing: 0.15px;
  margin: 0;
`;
