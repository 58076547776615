import styled from 'styled-components';

export const H2 = styled.h2`
  font-family: 'Muli', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 34px;
  padding: 0;
  margin: 32px 0 16px;
`;
