import styled from 'styled-components';

export const H3 = styled.h3`
  font-family: 'Muli', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 26px;
  padding: 0;
  margin: 0;
`;
